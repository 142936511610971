import './TableroMx.css';
import React, { useState, useEffect } from 'react';
import {
    GridLayout, GridLayoutItem,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardActions,
    TabStrip,
    TabStripTab
} from '@progress/kendo-react-layout';
import { useSelector, useDispatch } from 'react-redux';
import { getIncidentByRFC } from './requestServiceMx';
import { get_Left_Days } from './requestServiceMx';
import { setDynamicInputsData, selectDynamicInputsData } from '../../../../../redux/renewalsTreeReducer'
import { useUserService } from './../../services/userService'
import { selectConfig } from '../../../../../redux/renewalsTreeReducer';
const itemsPerPage = 12; // Number of items per page

const TableroMx = () => {
    const dispatch = useDispatch();
    const { userProfile } = useUserService();
    const renewalsConfig = useSelector(selectConfig)
    const dynamicInputsData = useSelector(selectDynamicInputsData);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };
    const [formControl, setFormControl] = useState({
        rfcSearch: null,
    })
    const [rfc, setRfc] = useState('');

    const generalFormSettings = [
        {
            label: "Rol",
            type: "select",
            options: [
                "Administrador(a)",
                "Asistente",
                "Asistente o Auxiliar Contable",
                "Contador",
                "Docente",
                "Dueño de la empresa",
                "Dueño del despacho",
                "Empresario",
                "Encargado de Soporte técnico",
                "Encargado del sistema",
                "Encargado(a)",
                "Estudiante",
                "Gerente de Operaciones",
                "No da información",
                "Representante legal",
                "Distribuidor",
                "Director"
            ]
        },
        {
            label: "Tipo de gestion",
            type: "select",
            options: [
                "Renov. Telefonica",
                "Renov. Whatsapp",
                "Renov. Correo"
            ]
        },
    ]

    const bySerialFormSettings = {
        "rfc": "",
        "serial": "",
        "producto": "",
        "fechaRenovacion": "",
        "medioDePago": "",
        "fechaCompromiso": "",
        "fechaExtendida": "",
        "notas": ""
    };
    const [rfcSerials, setRfcSerials] = useState([])
    const [rfcSavedData, setRfcSavedData] = useState([])
    const [rfcToProcess, setRfcToProcess] = useState({})
    const [days_left_month, setdays_left_month] = useState({})
    const [days_left_year, setdays_left_year] = useState({})
    useEffect(() => {
        get_Left_Days((data)=>{
            setdays_left_month(data.leftDaysToShow_month)
            setdays_left_year(data.leftDaysToShow_year)
        })
        dispatch(setDynamicInputsData({
            ...dynamicInputsData,
            user: userProfile.email,
            "tableroMx": {
                ...formControl
            }
        }))
    }, [formControl])

    const calculateExtendedDate = (days = null) => {
        let date = new Date();
        if ([4, 5, 6, 0].includes(date.getDay())) {
            date.setDate(date.getDate() + 1);
        }
        date.setDate(date.getDate() + parseInt(days != null ? days : renewalsConfig["Mexico"].daysToExtendedPromise));
        return date.toISOString().split('T')[0];
    }

    useEffect(() => {
        let generalFormSettingsStoreValues = {}
        generalFormSettings.map(el => {
            generalFormSettingsStoreValues[el.label.toLocaleLowerCase().replace(" ", "_")] = ""
        })
        setFormControl({
            ...formControl,
            ...generalFormSettingsStoreValues,
        })
        setIsLoading(false)
    }, [])
    
    const searchByNit = () => {
        if (validarRFC(rfc.trim())) {
            getIncidentByRFC(rfc, (data) => {
                if (data.base.length !== 0) {
                    setRfcSerials(data.base);
                    const hoy = new Date();
                    hoy.setHours(0, 0, 0, 0);
    
                    const compiled = data.base.map(item => {
                        let Fecha_renovacion = item.FECHA_RENOVACION;
                        const partesFecha = Fecha_renovacion.split('/');
                        let fecha_renovacion_dos_dias_atras = new Date();
    
                        if (partesFecha.length === 3) {
                            const [dia, mes, año] = partesFecha.map(num => parseInt(num, 10));
                            Fecha_renovacion = new Date(año, mes - 1, dia);
                            
                            // Usar days_left_month o days_left_year según la periodicidad
                            const days_left = item.PERIODICIDAD === 'Mensual' ? days_left_month : days_left_year;
                            const days_date = Fecha_renovacion.getDate() - parseInt(days_left);
                            fecha_renovacion_dos_dias_atras = new Date(año, mes - 1, days_date);
                        }
    
                        fecha_renovacion_dos_dias_atras.setHours(0, 0, 0, 0);
                        const checker = (fecha_renovacion_dos_dias_atras.getTime()) <= (hoy.getTime());
    
                        return {
                            ...item,
                            FechaRenovacion: Fecha_renovacion,
                            FechaRenovacionDosDiasAtras: fecha_renovacion_dos_dias_atras,
                            Checker: checker,
                            Estado: data.pagos.find(el => el.NUMSERIE === item.NUMSERIE) ? "Pagado" :
                                    data.promesas.find(el => el.serial === item.NUMSERIE) ? "Promesa" : "Pendiente"
                        };
                    });
    
                    const validRecords = compiled.filter(record => record.Checker);
                    const invalidRecords = compiled.filter(record => !record.Checker);
    
                    // Actualizar los datos de RFC guardados
                    if (validRecords.length > 0) {
                        setRfcSavedData(validRecords);
                        setFormControl({ ...formControl, rfcSearch: rfc });
                    }
    
                    // Mostrar alertas para los registros inválidos
                    if (invalidRecords.length > 0) {
                        invalidRecords.forEach(record => {
                            alert(`El registro indicado RFC tiene un serial con fecha de renovación superior al día establecido: 
                                   el serial es: ${record.NUMSERIE}, 
                                   La fecha en el registro es: ${record.FECHA_RENOVACION} 
                                   La fecha esperada es: ${record.PERIODICIDAD === 'Mensual' ? days_left_month : days_left_year} atrás, 
                                   para registros con periodicidad ${record.PERIODICIDAD}.`);
                        });
                    } else {
                        alert("Todos los registros son válidos.");
                    }
    
                } else {
                    alert("No se encontró el RFC");
                }
            });
        } else {
            alert("El registro indicado no es un RFC válido");
        }
    }
    

    const updateRfcToProcess = (serie, data) => {
        let tempRfcToProcess = { ...rfcToProcess }
        if (tempRfcToProcess[serie] !== undefined) {
            delete tempRfcToProcess[serie]
        } else {
            tempRfcToProcess[serie] = data
            tempRfcToProcess[serie]["fechaCompromiso"] = (new Date()).toISOString().split("T")[0];
            tempRfcToProcess[serie]["fechaExtendida"] = calculateExtendedDate();
        }

        let formcontrolBySerial = {}
        Object.keys(tempRfcToProcess).forEach(el => {
            let elContent = (formControl.formcontrolBySerial == undefined) ? bySerialFormSettings : (formControl.formcontrolBySerial[el] == undefined ? bySerialFormSettings : formControl.formcontrolBySerial[el])

            elContent = {
                ...elContent,
                "rfc": data.RFC,
                "serial": data.NUMSERIE,
                "producto": data.PRODUCTO,
                "fechaRenovacion": data.FECHA_RENOVACION,
                "medioDePago": data.FORMA_COBRO,
                "fechaCompromiso": data.fechaCompromiso,
                "fechaExtendida": data.fechaExtendida,
                "valor": data.MontoNeto
            }

            formcontrolBySerial[el] = {
                ...elContent,
                rfc: formControl.rfcSearch,
                serial: el,
            }
        })
        setFormControl(prevState => ({
            ...prevState,
            formcontrolBySerial: formcontrolBySerial
        }))
        setRfcToProcess(tempRfcToProcess);
    }

    const validarRFC = (valor) => {
        const regexRFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        return regexRFC.test(valor);
    };

    const pendingPayments = rfcSavedData.filter(el => el.Estado == "Pendiente")
    const realizedPayments = rfcSavedData.filter(el => el.Estado == "Pagado")
    const promisedPayments = rfcSavedData.filter(el => el.Estado == 'Promesa')

    return (
        isLoading ? <div className="loading">Loading&#8230;</div> :
            <>
                <GridLayout
                    gap={{ rows: 8, cols: 8, }}
                    className="MxPaymentPromises">
                    <GridLayoutItem>
                        <GridLayout
                            gap={{ rows: 8, cols: 8, }}
                            cols={[{ width: "auto" }, { width: "70%" }]}>
                            <GridLayoutItem className='siignal-box-shadow'>
                                <Card>
                                    <CardHeader
                                        className="k-hbox"
                                    >
                                        <div>
                                            <CardTitle>
                                                <strong>RFC a buscar:</strong>
                                            </CardTitle>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <input
                                            placeholder='XXXX00000X0'
                                            className='.mxPaymentPromisesInput'
                                            value={rfc}
                                            onChange={(e) => {
                                                setRfc(e.target.value)
                                            }} />
                                    </CardBody>
                                    <CardActions
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>

                                            <button
                                                className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                                                onClick={() => {
                                                    searchByNit()
                                                }}
                                            >
                                                Buscar
                                            </button>
                                        </div>
                                    </CardActions>
                                </Card>
                            </GridLayoutItem>
                            <GridLayoutItem className='siignal-box-shadow'>
                                ESTADISTICAS / TABLA / GRAFICOS
                                <hr />
                                AACV7011241D3
                            </GridLayoutItem>
                        </GridLayout>
                    </GridLayoutItem>
                    {
                        (formControl.rfcSearch != null && rfcSavedData.length != 0) ? <>
                            <GridLayoutItem className='siignal-box-shadow'>
                                <TabStrip selected={selected} onSelect={handleSelect}>
                                    <TabStripTab title={`Pendientes de pago (${pendingPayments.length})`}>
                                        <div className='siignalCenteredText'>
                                            {
                                                (promisedPayments.length != 0) ? <>
                                                    <b>Este cliente ya tiene {promisedPayments.length} promesas creadas</b>
                                                </> : null
                                            }
                                            <br />
                                        </div>
                                        {
                                            (pendingPayments.length != 0) ? <>
                                                <div className='siignalCenteredText'>Estos precios son de referencia y debe realizar CENTRAL</div>
                                                <table className="renewalsMX-SerialsList">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Gestionar</th>
                                                            <th>Numero Serie</th>
                                                            <th>Periodicidad</th>
                                                            <th>Producto</th>
                                                            <th>Monto Neto</th>
                                                            <th>Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pendingPayments.map((el, index) => {
                                                                return (
                                                                    <>
                                                                        <tr
                                                                            key={index}
                                                                            className={rfcToProcess[el.NUMSERIE] != undefined || el.Estado == "Pagado" ? "success" : "danger"}
                                                                            onClick={() => {
                                                                                updateRfcToProcess(el.NUMSERIE, el)
                                                                            }}>
                                                                            <td>{index + 1}</td>
                                                                            <td>
                                                                                {
                                                                                    rfcToProcess[el.NUMSERIE] != undefined ? "SI" : "NO"
                                                                                }
                                                                            </td>
                                                                            <td>{el.NUMSERIE}</td>
                                                                            <td>{el.PERIODICIDAD}</td>
                                                                            <td>{el.PRODUCTO}</td>
                                                                            <td>MXN${el["MontoNeto"]}</td>
                                                                            <td>{el.Estado}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </> : <>
                                                <div className='siignalCenteredText'>
                                                    Este cliente no tiene pagos pendientes
                                                </div>
                                            </>
                                        }
                                    </TabStripTab>
                                    <TabStripTab title={`Pagados (${realizedPayments.length})`}>
                                        {
                                            (realizedPayments.length != 0) ? <>
                                                <table className="renewalsMX-SerialsList">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Gestionar</th>
                                                            <th>Numero Serie</th>
                                                            <th>Periodicidad</th>
                                                            <th>Producto</th>
                                                            <th>Monto Neto</th>
                                                            <th>Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            realizedPayments.map((el, index) => {
                                                                return (
                                                                    <>
                                                                        <tr
                                                                            key={index}
                                                                            className={rfcToProcess[el.NUMSERIE] != undefined || el.Estado == "Pagado" ? "success" : "danger"}>
                                                                            <td>{index + 1}</td>
                                                                            <td>
                                                                                {
                                                                                    rfcToProcess[el.NUMSERIE] != undefined ? "SI" : "NO"
                                                                                }
                                                                            </td>
                                                                            <td>{el.NUMSERIE}</td>
                                                                            <td>{el.PERIODICIDAD}</td>
                                                                            <td>{el.PRODUCTO}</td>
                                                                            <td>MXN${el["MontoNeto"]}</td>
                                                                            <td>{el.Estado}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </> : <>
                                                <div className='siignalCenteredText'>
                                                    Este cliente no tiene pagos registrados.
                                                    <br />
                                                    <b>NOTA:</b> Esta informacion se actualiza a dia vencido, por lo que es posible que algunos pagos aun no los tengamos.
                                                </div>
                                            </>
                                        }
                                    </TabStripTab>
                                </TabStrip>
                            </GridLayoutItem>
                            <GridLayoutItem>
                                <GridLayout
                                    gap={{ rows: 8, cols: 8, }}
                                    cols={[{ width: "auto" }, { width: "auto" }]}>
                                    {
                                        generalFormSettings.map((field, index) => {
                                            return (
                                                <GridLayoutItem className='siignal-box-shadow'>
                                                    <h6>{field.label}</h6>
                                                    {
                                                        field.type == "select" ? <>
                                                            <select onChange={(event) => {
                                                                setFormControl({
                                                                    ...formControl,
                                                                    [field.label.toLocaleLowerCase()]: event.target.value
                                                                })
                                                            }}>
                                                                <option value="">Selecciona una opción</option>
                                                                {
                                                                    field.options.map(option => {
                                                                        return (
                                                                            <option key={option} value={option}>{option}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </> : <>
                                                            <input
                                                                className='renewalsMX-SerialsList-input'
                                                                type={field.type} />

                                                        </>
                                                    }
                                                </GridLayoutItem>
                                            )
                                        })
                                    }
                                </GridLayout>
                            </GridLayoutItem>

                            <GridLayoutItem className='renewalsMX-mt'>
                                <table className="renewalsMX-SerialsList">
                                    <thead>
                                        <tr>
                                            <th>COD</th>
                                            <th>Product</th>
                                            <th>Num Serial</th>
                                            <th>Fecha Renovacion</th>
                                            <th>Medio de Pago</th>
                                            <th>Saldo</th>
                                            <th>Fecha Compromiso</th>
                                            <th>Fecha Extendida</th>
                                            <th>Notas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(rfcToProcess).map((serial, index) => {
                                                const serialData = rfcToProcess[serial]
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{serialData.PRODUCTO}</td>
                                                            <td>{serial}</td>
                                                            <td>{serialData["FECHA_RENOVACION"]}</td>
                                                            <td>{serialData["FORMA_COBRO"]}</td>
                                                            <td>MXN${serialData["valor"]}</td>
                                                            <td>
                                                                <input
                                                                    type="date"
                                                                    value={serialData["fechaCompromiso"]}
                                                                    onChange={(e) => { }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="date"
                                                                    value={serialData["fechaExtendida"]}
                                                                    onChange={(e) => { }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    value={rfcToProcess[serial].notas}
                                                                    onChange={(e) => {
                                                                        setFormControl(prevState => ({
                                                                            ...prevState,
                                                                            formcontrolBySerial: {
                                                                                ...prevState.formcontrolBySerial,
                                                                                [serial]: {
                                                                                    ...prevState.formcontrolBySerial[serial],
                                                                                    notas: e.target.value,
                                                                                },
                                                                            },
                                                                        }));
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </GridLayoutItem>
                        </> : null
                    }
                    {
                        (formControl.rfcSearch != null && rfcSerials.length == 0) ? <>
                            <div class="siigoRenewalsCenterText">Este RFC no se encuentra en la base de datos.</div>
                        </> : null
                    }

                    {/* 
                    TODO: Add control for all serial taken
                     */}
                </GridLayout >
            </>
    );
};

export default TableroMx;
