import { Button } from '@progress/kendo-react-buttons'
import { Dialog } from '@progress/kendo-react-dialogs'
import React, { useState } from 'react'
import { MdDeleteOutline } from 'react-icons/md'
import { DeleteReportsMenu } from './menuOfDelete/DeleteReportsMenu'
import { useSelector } from 'react-redux'
import { selectCredencialesArea, selectCredencialesRole } from '../../../../../redux/credencialesReducer'
import { IsAuthorized } from '../../../../../components/auth/IsAuthorized'

export const DeleteReport = ({setToast,name,reportId}) => {
const [visibleUploadReport, setVisibleUploadReport] = useState(false)
const area = useSelector(selectCredencialesArea);
const rol=useSelector(selectCredencialesRole);
const IsAllowed=IsAuthorized('HelpDesk-dataPartner',area,rol )
const toggleDialogUploadReport = () => {
    setVisibleUploadReport(!visibleUploadReport)
}
const onDelete = () => {
    setVisibleUploadReport(!visibleUploadReport)
}
  return (<>
    {visibleUploadReport && (
        <Dialog
            title={'Eliminar'}

            onClose={toggleDialogUploadReport}
            width={'30%'}>
            {' '}
            <DeleteReportsMenu
                setVisibleUploadReport={setVisibleUploadReport}
                name={name}
                setToast={setToast}
                reportId={reportId}
                onDelete={onDelete}
            />{' '}
        </Dialog>
    )}
    {
        IsAllowed&&(
            <Button  style={{'width':'25px','height':'25px'}} className={'btnrojo'} onClick={onDelete}><MdDeleteOutline /></Button>
        )
    }
    </>
  )
}
