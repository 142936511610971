import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setChecking} from '../../../redux/credencialesReducer';
import {controller} from '../../../components/ViewCustomer/db/controller';
import { baseURL } from '../../../Config';

export const UseChargeData = (eMail) => {
    const dispatch = useDispatch();
    const [view, setView] = useState('home')
    const [params, setParams] = useState('name');
    

    useEffect(() => {
        const fetchDataBasedOnEmail= async()=>{
            dispatch(setChecking(true));
            console.log("Entorno:", process.env.NODE_ENV);
            console.log("production:", process.env.REACT_APP_API_URL_PROD);
            let homeViewData = await controller(`${baseURL}/api/v1/ViewsHome/Views`, 'POST', {
                email: eMail,
            });
            if (homeViewData !== null) {           
                setParams(homeViewData['asesor']);

                if(homeViewData['area']==='Sales'){
                    const comercialData = await controller(`${baseURL}/api/v1/Sales/Rep/KPI/View`, 'POST', {
                        email: eMail,
                    });
                    if (comercialData !== null) {
                        JSON.stringify(comercialData);
                        console.log(comercialData);
                        if (comercialData && comercialData.hasOwnProperty('asesor')) {
                            sessionStorage.setItem('dataComercial', JSON.stringify(comercialData));
                            setParams(comercialData['asesor']);
                            setView('comercial');
                          } else {
                            sessionStorage.setItem('dataComercial', JSON.stringify(comercialData));
                            setView('CrossSell');
                          }
                    }else{

                        setView('home');
                    }
                    sessionStorage.setItem('customReports', JSON.stringify(homeViewData));              
                }else{                    
                    setView(homeViewData['area']);
                    sessionStorage.setItem('customReports', JSON.stringify(homeViewData));     
                }

            }else{
                setView('default');
            }
            dispatch(setChecking(false));
        }
        fetchDataBasedOnEmail(eMail);
    }, [eMail,dispatch]);

   

    return {view,params};
	
};
