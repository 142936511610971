import * as React from 'react';


import { Popup } from "@progress/kendo-react-popup";

import { xIcon, sparklesIcon } from "@progress/kendo-svg-icons";
import { FloatingActionButton } from "@progress/kendo-react-buttons";
import ChatBox from '../Chatbot/components/ChatBox';


const Chatmicrosoft = ({isAllowedChatbotAlice}) => {
	const anchorRef = React.useRef(null);
	const iframeSrc = process.env.REACT_APP_IFRAME_SRC;
  const [textAreaValue, setTextAreaValue] = React.useState("");
  const [localPrompt, setLocalPrompt] = React.useState();
  const [showAIPrompt, setShowAIPrompt] = React.useState(false);
  const [outputs, setOutputs] = React.useState([]);
  const toggleAIPrompt = () => {
    setShowAIPrompt(!showAIPrompt);
  };
	return (
		<>
			<FloatingActionButton
				style={{
					zIndex: 100,
				}}
				ref={anchorRef}
				svgIcon={showAIPrompt ? xIcon : sparklesIcon}
				align={{
					horizontal: 'end',
					vertical: 'bottom',
				}}
				alignOffset={{
					x: 40,
					y: 40,
				}}
				positionMode="absolute"
				onClick={toggleAIPrompt}
			/>
			<Popup
				anchor={anchorRef.current?.element}
				show={showAIPrompt}
				popupAlign={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				anchorAlign={{
					vertical: 'bottom',
					horizontal: 'left',
				}}>
					{!isAllowedChatbotAlice?<html>
					<body>
						<iframe
							src={iframeSrc}
							width="500px"
							height="700px"
							frameborder="0"></iframe>
					</body>
				</html>:<ChatBox/>}
				
			</Popup>
		</>
	);
};

export default Chatmicrosoft;
