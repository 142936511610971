import { useState, useEffect } from 'react';
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../Config";
import { callMsGraph } from '../../Graph';
import { useDispatch } from 'react-redux';
import { setChecking, setCredenciales } from '../../redux/credencialesReducer';

export const UseRoles = (isAuthenticated) => {
    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();
    const [roleData, setRoleData] = useState(null);
    const [loadingRoles, setLoadingRoles] = useState(true);

    useEffect(() => {
        if (!isAuthenticated) {
            setLoadingRoles(false);
            return;
        }

        const email = accounts[0].username;
        const fetchRoles = async () => {
            dispatch(setChecking(true));

            try {
                const tokenResponse = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0]
                });

                const userDetails = await callMsGraph(tokenResponse.accessToken);
                
                if(
                    // userDetails?.onPremisesDistinguishedName?.includes("OU=Consultores") &&
                    userDetails?.department === 'Consultores Externos Siigo' &&
                    userDetails?.employeeId === null
                ){                    
                    setRoleData({
                        role: 'Guest',
                        subrole: 'Guest',
                        userDetails: userDetails.displayName
                    });
                    sessionStorage.setItem("name", userDetails.displayName);
                    sessionStorage.setItem("usr", userDetails.onPremisesUserPrincipalName);
                    sessionStorage.setItem("Company", userDetails.companyName);
                    sessionStorage.setItem("department", userDetails.department);
                    let tempData={
                        Nombre: userDetails.displayName,
                        E_mail_Corporativo_1: userDetails.onPremisesUserPrincipalName,
                        companyName: userDetails.companyName,
                        department:userDetails.department,
                        Area:'Alianzas',
                        Role: userDetails.department
                    }
                    dispatch(setCredenciales(tempData));
                    return{ roleData};
                }

                const userResponse = await axios.get(`/getuser?id=${email}`);
                const userData = userResponse.data;

                if(!userData.Nombre){
                    setRoleData({
                        role: 'Not Found',
                        area: null,
                        userDetails: undefined
                    });
                    console.log('No se encontró el usuario en la base de datos');
                    return{ roleData};
                }

                if(!userData.hasOwnProperty('Role')){                   
                    userData.Role = 'User';
                }
                // Object.entries(userData).forEach(([key, value]) => {
                //     sessionStorage.setItem(key, value);
                // });

                sessionStorage.setItem('role', userData.Role );
                sessionStorage.setItem("area", userData.Area||null);
                sessionStorage.setItem("country", userData.Pais_de_Contratacion||'CO');
                sessionStorage.setItem("name", userData.Nombre);
                sessionStorage.setItem("usr", userData.E_mail_Corporativo_1);
                sessionStorage.setItem("subarea", userData.SubArea||null);
                userData['companyName']=userDetails.companyName
                userData['department']=userDetails.department
                dispatch(setCredenciales(userData));

                setRoleData({
                    role: userData.Role || 'user',
                    area: userData.Area || null,
                    userDetails: userDetails
                });

                const setrole=sessionStorage.getItem('role')
                const username=`${email}`
                const user ={username, setrole}
                axios.post('/api/userLogs', user)
                .then((response) => {
                }).catch((error) => {
                    console.error('Error Logs', error);
                });
                    


            } catch (error) {
                console.error('Error loading roles:', error);
            } finally {

                dispatch(setChecking(false));
                setLoadingRoles(false);
            }
        };

        fetchRoles();
    }, [instance, accounts, dispatch, isAuthenticated]);

    return { roleData, loadingRoles };
};
