import {Badge} from '@mui/base';
import {Dialog} from '@progress/kendo-react-dialogs';
import {Switch} from '@progress/kendo-react-inputs';
import {
	Avatar,
	Card,
	CardBody,
	CardHeader,
	CardSubtitle,
	CardTitle,
	StackLayout,
	TabStrip,
	TabStripTab,
	TileLayout,
} from '@progress/kendo-react-layout';
import {useEffect, useState} from 'react';
import {TrainingTopicsTable} from '../trainingTopicsTable';
import {TrainingParticipantsTable} from '../trainingParticipantsTable';
import {TrainingParticipantsExternalsTable} from '../TrainingParticipantsExternalsTable';
import {ChargeMassive} from '../chargeMassive/ChargeMassive';
import {images} from '../images';
import {useDispatch, useSelector} from 'react-redux';
import {
	selectDetalleCapacitacion,
	setDetalleCapacitacion,
	setListaDeCapacitaciones,
} from '../../../redux/TNBSlice';
import axios from 'axios';
import {CircularProgress} from '@mui/material';
import RangeScore from './scoreComponent';

export const DetailTrainingDialog = ({toggleDialog, trainingCode}) => {
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [selected, setSelected] = useState(1); //telerik pestaña activa
	const capacitacion = useSelector(selectDetalleCapacitacion); //detalle de la capacitación completa
	const dispatch = useDispatch();
	const handleSelect = (e) => {
		setSelected(e.selected);
	};
	const handleChangeSwitch = () => {
		const statusUpdate = {estado: !capacitacion.estado};

		axios
			.post(
				`/api/TNB/Training?id=${capacitacion.nombreCapacitacion}`,
				statusUpdate
			)
			.then((response) => {
				dispatch(setDetalleCapacitacion(response.data));
			});
		//aca modifica el estado de una capacitacion, además trae de nuevo la capacitación cpmpleta, solamente le cambio el estado
	};

	useEffect(() => {
		if (capacitacion === null) {
			const timer = setTimeout(() => {
				setError('Creo que hay problemas con esta capacitación');
				setIsLoading(false); // Esto esconde el CircularProgress después de establecer el error
			}, 5000);

			// Si el componente se desmonta antes de que el timeout finalice, limpiarlo
			return () => clearTimeout(timer);
		} else {
			// Si capacitacion no es null, no necesitas mostrar el error o el loader
			setIsLoading(false);
		}
	}, [capacitacion]);

	useEffect(() => {
		return () => {
			dispatch(setDetalleCapacitacion(null));
		};
	}, [dispatch]);
	const ProjectCell = (props) => {
		const [open, setOpen] = useState(false);
		const onClick = () => {
			setOpen(!open);
		};
		return (
			<li>
				<b>Proyecto: </b>
				<span>{props.data.nombreProyecto}</span>
				<span className='p-hover' style={{marginLeft:'5px',fontWeight: '200', fontSize: '12px'}} onClick={onClick}>
					{open?'(Ocultar)':'(Ver más)'}
				</span>
				{open && <p>{props.data.descripción}</p>}
			</li>
		);
	};

	let tiles2 = [];
	if (capacitacion) {
		tiles2 = [
			{
				defaultPosition: {
					col: 1,
					colSpan: 3,
					rowSpan: 2,
				},
				item: (
					<Card>
						<CardHeader
							className="k-hbox"
							style={{
								background: 'transparent',
								// minHeight:'70px',
							}}>
							<Avatar
								type="image"
								size="small"
								rounded={'small'}
								themeColor={'base'}>
								<img
									src={images[capacitacion.pais]}
									style={{verticalAlign: 'middle', borderStyle: 'none'}}
									alt={capacitacion.pais}
								/>
							</Avatar>
							<div>
								<StackLayout orientation={'horizontal'} gap={'10%'}>
									<CardTitle
										style={{
											fontSize: '17px',
											fontWeight: 700,
										}}>
										{capacitacion.nombreCapacitacion}
									</CardTitle>
									{capacitacion.estado === true ? (
										<div style={{width:'55px'}}>
											<Switch
											defaultChecked={true}
											onLabel={'I'}
											offLabel={'O'}
											onChange={handleChangeSwitch}
										/>
										</div>
									) : (
										<div style={{width:'55px'}}>
										<Switch
											defaultChecked={false}
											onLabel={'I'}
											offLabel={'O'}
											onChange={handleChangeSwitch}
										/>
										</div>
									)}
								</StackLayout>
								<div
									style={{
										fontWeight: 400,
										marginLeft: '140px',
										marginTop: '-2px',
										fontSize: '12px',
									}}>
									{capacitacion.estado === true ? (
										<Badge
											themeColor={'success'}
											shape={'rounded'}
											fillMode={'outline'}
											style={{
												position: 'relative',
												display: 'inline',
											}}>
											Activo
										</Badge>
									) : (
										<Badge
											themeColor={'light'}
											shape={'rounded'}
											style={{
												position: 'relative',
												display: 'inline',
											}}>
											Inactivo
										</Badge>
									)}
								</div>
							</div>
						</CardHeader>
						<CardBody>
							<ul>
								<li>
									<b>Entrenador principal:</b> {capacitacion.nombreEntrenador}
								</li>
								<li>
									<b>Entrenador Secundario:</b>{' '}
									{capacitacion.entrenadorAdicional === undefined
										? ''
										: capacitacion.entrenadorAdicional}
								</li>
								<div style={{display:'flex',gap:'94px'}}>
								<li>
									<b>Total de Personas Registradas: </b>{capacitacion?.totalPersRegistradas}
								</li>
								<li>
									<b>Area:</b> {capacitacion.area}
								</li>
								</div>
								<div style={{display:'flex',gap:'55px'}}>
								<li>
									<b>Fecha Inicio:</b> {capacitacion.fechaIncioCapacitacion}
								</li>
								<li>
									<b>Fecha Fin:</b> {capacitacion.fechaFinCapacitacion}
								</li>
								</div>
								{capacitacion && capacitacion.project ? (
									<ProjectCell data={capacitacion.project} />
								) : (
									'N/A'
								)}
							</ul>
						</CardBody>
					</Card>
				),
				resizable: true,
				reorderable: false,
			},
			{
				defaultPosition: {
					col: 4,
					colSpan: 1,
					rowSpan: 2,
				},

				body: (
					<div
						style={{
							borderRadius: '6px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<RangeScore capacitacion={capacitacion} />
					</div>
				),
				resizable: true,
				reorderable: false,
			},
		];
	} else {
		tiles2 = 'Loading...';
	}

	if (isLoading) {
		return (
			<Dialog
				title={'Detalle Capacitación'}
				onClose={toggleDialog}
				width={'80%'}
				height={'80%'}>
				<div>
					<div className="centerDS">
						<CircularProgress />
					</div>
				</div>
			</Dialog>
		);
	}

	if (error) {
		return (
			<Dialog
				title={'Detalle Capacitación'}
				onClose={toggleDialog}
				width={'80%'}
				height={'80%'}>
				<div>
					<h2>{error}</h2>
				</div>
			</Dialog>
		);
	}

	return (
		<Dialog
			title={'Detalle Capacitación'}
			onClose={toggleDialog}
			width={'80%'}
			height={'80%'}>
			<div>
				<>
					<TileLayout
						columns={4}
						gap={{
							rows: 12,
							columns: 12,
						}}
						items={tiles2}
					/>
					<br />
					<TabStrip selected={selected} onSelect={handleSelect}>
						<TabStripTab title="Temas">
							<TrainingTopicsTable value={trainingCode} />
						</TabStripTab>
						<TabStripTab title="Participantes Internos">
							<TrainingParticipantsTable value={trainingCode} />
						</TabStripTab>
						<TabStripTab title="Participantes Externos">
							<TrainingParticipantsExternalsTable value={trainingCode} />
						</TabStripTab>
						<TabStripTab title="Carga Masiva">
							<ChargeMassive status={capacitacion.estado} />
						</TabStripTab>
					</TabStrip>
				</>
			</div>
		</Dialog>
	);
};
